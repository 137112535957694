<template>
  <div class="GlobalTable">
    <div style="text-align: right;margin-bottom: 20px">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="addCertificate('add')">添加证书</el-button>
    </div>
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="证书名称" slot="certName" align="center">
        <template slot-scope="{ row }">
          <span @click="addCertificate('update', row)" style="color: #409eff;cursor: pointer;text-decoration: underline;">
            {{ row.certName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="证书说明" slot="operationtitle" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.certDescribe" placement="top">
            <p class="clampThird">
              {{ row.certDescribe }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="证书状态" slot="state" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.state == '01' ? '生效中' : row.state == '02' ? '已过期' : '已停用' }}</span>
          <p v-if="row.isWillExpire" style="color: red;">即将到期</p>
        </template>
      </el-table-column>
      <el-table-column label="距离到期还有" slot="operationtime" align="center">
        <template slot-scope="{ row }">
          <span :style="{ color: row.isWillExpire ? 'red' : '' }">{{ row.warnHasDays }}天</span>
        </template>
      </el-table-column>
      <el-table-column label="到期前X天预警" slot="yuoperation" align="center">
        <template slot-scope="{row}">
          <span>{{ row.warnDays }}天</span>
        </template>
      </el-table-column>
      <el-table-column label="添加人" slot="account" align="center">
        <template slot-scope="{row}">
          <span>{{ row.createUsername }}-{{ row.createAccount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="延期记录" slot="account" align="center">
        <template slot-scope="{row}">
          <span @click="deferredRecord(row)" style="color: #e6a23c;cursor: pointer;text-decoration: underline;">{{
            row.delayNum
          }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="success" v-if="row.state != '03'" round
            @click="handleBtn(row, 'postpone')">证书延期</el-button>
          <el-button size="mini" type="danger" v-if="row.state != '03'" round
            @click="handleBtn(row, 'stop')">停用证书</el-button>
          <el-button size="mini" type="warning" v-if="row.state == '03'" round
            @click="handleBtn(row, 'record')">查看停用记录</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <el-drawer title="+添加证书" :visible.sync="drawer" direction="rtl" size="35%" :show-close="false"
      :wrapperClosable="false" :before-close="siginClose">
      <div class="center_book">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <div>
            <p style="margin-bottom: 15px;">证书信息</p>
            <el-form-item label="证书名称" prop="certName">
              <el-input :disabled="CertificateType != 'add'" v-model="ruleForm.certName" maxlength="30"
                placeholder="请输入证书名称，最多30字"></el-input>
            </el-form-item>
            <el-form-item label="证书说明" prop="certDescribe">
              <el-input :disabled="CertificateType != 'add'" type="textarea" placeholder="请输入证书说明，最多200字" maxlength="200"
                v-model="ruleForm.certDescribe"></el-input>
            </el-form-item>
            <el-form-item label="证书截止日期" required label-width="110px">
              <el-col :span="11">
                <el-form-item prop="lifespanTime">
                  <el-date-picker :disabled="CertificateType != 'add'" :picker-options="pickerOptions"
                    value-format="timestamp" type="date" placeholder="选择日期" v-model="ruleForm.lifespanTime"
                    style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
          </div>
          <div>
            <p style="margin-bottom: 15px;">预警设置</p>
            <el-form-item label="距离到期剩" prop="warnDays">
              <el-col :span="10">
                <el-select clearable v-model="ruleForm.warnDays" placeholder="请选择">
                  <el-option v-for="(item, index) in timeList" :key="index" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-col>
              <span style="margin-left: 5px;">天时预警</span>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button size="small" @click="resetForm('ruleForm')">取消</el-button>
            <el-button type="primary" size="small" @click="submitForm('ruleForm')">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!--延期记录-->
    <el-dialog :title="'延期记录-' + recorderListCopy.certName" :visible.sync="dlogincodeVisible" :close-on-click-modal="false"
      width="800px" @closed="dlogincodeVisible = false; postponeDataList = []">
      <GlobalTable ref="GlobalTable" v-loading="loading" :columns="postponeTableColumns" :data="postponeDataList"
        :currentPage="postponepage.pageNum" :total="postponepage.total" @handleCurrentChange="postponeCurrentChange">
        <el-table-column label="操作人" slot="operationAccount" align="center">
          <template slot-scope="{ row }">
            <span>
              {{ row.createUsername }}-{{ row.createAccount }}
            </span>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
    <!--证书停用记录-->
    <el-dialog title="证书停用记录" :visible.sync="dialogVisible" :close-on-click-modal="false" width="380px"
      @closed="handleDialogClosed">
      <div style="margin-bottom: 10px;">证书名称：{{ actionData.certName }}</div>
      <div style="margin-bottom: 10px;">停用操作人：{{ actionData.disableUsername }}-{{ actionData.disableAccount }}</div>
      <div style="margin-bottom: 10px;">停用操作时间：{{ actionData.disableTime }}</div>
      <div style="margin-bottom: 10px;">停用原因：{{ actionData.disableCause }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click.native="handleClose">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 停用证书提示 -->
    <el-dialog title="停用证书提示" :visible.sync="cancelVisible" :close-on-click-modal="false" width="430px"
      @closed="handleCancelClosed">
      <div style="display:flex;flex-direction: column;align-items: center">
        <span class="remark">说明：停用后将不再对该证书进行预警提醒，请核实后再操作</span>
        <div style=" font-size: 16px;color: #666666; height: 60px; line-height: 60px;tetx-align:center; ">
          <div>是否确认停用该证书？</div>
        </div>
        <div class="command">
          <div style="width: 100px;"><span style="color: red;">*</span>停用原因：</div>
          <el-input type="textarea" placeholder="请输入停用原因，最多50字" maxlength="50" v-model="stopRsult"></el-input>
        </div>
        <div class="command">
          <div style="width: 80px;"><span style="color: red;">*</span>动态口令：</div>
          <el-input v-model="command" placeholder="请输入谷歌动态验证码"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handlecancelSuccess">确定</el-button>
        <el-button @click.native="handleCancelClosed">取消</el-button>
      </span>
    </el-dialog>
    <!-- 证书延期 -->
    <el-dialog title="证书延期" :visible.sync="extensionVisible" :close-on-click-modal="false" width="430px"
      @closed="extensionClosed">
      <div style="display:flex;flex-direction: column;align-items: center">
        <span class="remark">说明：需重新设置证书有效期，提交后将按照新的证书有消息执行。</span>
        <div style="font-size: 14px;color: #666666; text-align: left;width: 100%;margin: 20px 0; ">
          <div>当前证书截止日期：<span>{{ actionData.lifespanTime }}</span></div>
        </div>
        <div class="command">
          <div style="width: 180px;"><span style="color: red;">*</span>修改后截止日期：</div>
          <el-date-picker type="date" :picker-options="{ disabledDate }" value-format="timestamp" placeholder="请选择"
            v-model="deadline" style="width: 100%;"></el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="extensionSuccess">确定</el-button>
        <el-button @click.native="extensionClosed">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
export default {
  name: "Table",
  data() {
    return {
      actionData: {},
      CertificateType: "",
      deadline: "",
      command: "",
      stopRsult: "",
      extensionVisible: false,
      drawer: false,
      timeList: [{
        label: "3天",
        value: 3,
      }, {
        label: "5天",
        value: 5,
      }, {
        label: "7天",
        value: 7,
      }, {
        label: "15天",
        value: 15,
      }, {
        label: "30天",
        value: 30,
      }],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 8.64e7;
        },
      },
      loading: false,
      ruleForm: {
        certName: '',
        warnDays: '',
        lifespanTime: '',
        certDescribe: ''
      },
      rules: {
        certName: [
          { required: true, message: '请输入证书名称', trigger: 'blur' },
        ],
        warnDays: [
          { required: true, message: '请选择天数', trigger: 'change' }
        ],
        lifespanTime: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        certDescribe: [
          { required: true, message: '请输入证书说明', trigger: 'blur' }
        ]
      },
      postponepage: {
        pageNum: 1,
        total: 0,
      },
      page: {
        pageNum: 1,
        total: 0,
      },
      dlogincodeVisible: false,
      recoderpagenum: 1,
      seachDataList: [],
      postponeDataList: [],
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      dialogWidth: 380,
      dialongDecide: "",
      resetId: "", //密码重置ID
      tableColumns: [
        { slotName: "certName" },
        { slotName: "operationtitle" },
        { slotName: "state" },
        { label: "证书截止日期", prop: "lifespanTime" },
        { slotName: "operationtime" },
        { slotName: "yuoperation" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "account" },
        { slotName: "operation" }
      ],
      postponeTableColumns: [
        { slotName: "operationAccount" },
        { label: "操作时间", prop: "createTime" },
        { label: "操作前截止日期", prop: "oldLifespanTime" },
        { label: "操作后截止日期", prop: "newLifespanTime" },
      ],
      cancelVisible: false,
      recorderListCopy: {}
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  created() {
    this.handleCurrentChange();
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal)
      this.handleCurrentChange()
    }
  },
  methods: {
    disabledDate(time) {
      // new Date(this.actionData.lifespanTime).getTime()-8.64e7表示可选择当天日期
      return (
        time.getTime() <
        new Date(this.actionData.lifespanTime).getTime()
      );
    },
    //证书停用
    handlecancelSuccess() {
      const params = {
        webCertId: this.actionData.webCertId,
        disableCause: this.stopRsult,
        command: this.command,
      };
      _api.doDisableWebcerts(params).then((res) => {
        if (res.code === 1) {
          this.$message.success("操作成功");
          this.cancelVisible = false;
          this.stopRsult = ""
          this.command = ""
          this.actionData = {}
          this.handleCurrentChange()
        }
      });
    },
    handleCancelClosed() {
      this.command = "";
      this.stopRsult = "";
      this.cancelVisible = false;
    },
    extensionSuccess() {
      let dodelParams = {
        "lifespanTime": this.deadline,
        "webCertId": this.actionData.webCertId
      }
      _api.doDelayWebcerts(dodelParams).then(res => {
        if (res.code == 1) {
          this.$message({
            message: '操作成功！',
            type: 'success'
          });
          this.deadline = ""
          this.extensionVisible = false
          this.handleCurrentChange()
        }

      })
    },
    extensionClosed() {
      this.deadline = ""
      this.extensionVisible = false
    },
    //延期记录
    deferredRecord(row) {
      this.recorderListCopy = row
      this.dlogincodeVisible = true
      this.postponeCurrentChange(1)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const awObject = JSON.parse(JSON.stringify(this.ruleForm))
          console.log(this.ruleForm, awObject);
          if (this.CertificateType == 'add') {
            _api.addWebcerts(awObject).then(res => {
              if (res.code == 1) {
                this.$message({
                  message: '证书添加成功！',
                  type: 'success'
                });
                this.drawer = false
                this.handleCurrentChange()
              }
            })
          } else {
            let upPrams = {
              "warnDays": awObject.warnDays,
              "webCertId": awObject.webCertId
            }
            _api.updateWebcerts(upPrams).then(res => {
              if (res.code == 1) {
                this.$message({
                  message: '证书编辑成功！',
                  type: 'success'
                });
                this.drawer = false
                this.handleCurrentChange()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.drawer = false
    },
    //添加证书
    addCertificate(type, data) {
      this.CertificateType = type
      if (type == 'add') {
        this.ruleForm = {
          certName: '',
          warnDays: '',
          lifespanTime: '',
          certDescribe: ''
        }
      } else {
        this.ruleForm = JSON.parse(JSON.stringify(data))
        this.ruleForm.lifespanTime = moment(this.ruleForm.lifespanTime).format("x")
      }
      this.drawer = true
    },
    siginClose(done) {
      this.drawer = false
    },
    //延期记录
    postponeCurrentChange(val) {
      console.log(val);
      const baseRequest = {
        pageNum: val || 1,
        pageSize: 10,
        webCertId: this.recorderListCopy.webCertId
      };
      _api.delayLogsRecord(baseRequest).then(res => {
        if (res.code === 1) {
          this.postponeDataList = res.data.records;
          this.postponepage.total = res.data.total;
          this.postponepage.pageNum = res.data.current;
        }
      });
    },
    // 表格切换页面
    handleCurrentChange(val) {
      this.recoderpagenum = val || 1
      console.log(val, this.recoderpagenum);
      const baseRequest = {
        webCertId: this.SeachParams.webCertId,
        state: this.SeachParams.state,
        pageNum: val || 1,
        pageSize: 10,
      };
      _api.WebcertList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 表格按钮操作
    handleBtn(row, type) {
      this.actionData = row
      console.log("表格操作", type, row);
      if (type === "record") {
        this.dialogVisible = true
      } else if (type === "postpone") {
        this.extensionVisible = true
      } else {
        this.cancelVisible = true;
      }
    },
    //启用/禁用账号
    handleSwitch(row) {
      this.dialogVisible = true;
      this.dialogTitle = "启用/禁用账号";
      this.dialongDecide = "switch";
    },
    //弹窗取消
    handleDialogClosed() {
      this.dialogVisible = false;
      this.handleCurrentChange(this.recoderpagenum);
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {}

.clampThird {
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.center_book {
  padding: 5px 20px;
}

.left_fg {
  font-size: 14px;
  text-align: left;

  >p {
    margin-bottom: 10px;
  }
}

.stydata {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remark {
  font-size: 12px;
  color: #ff687b;
}

.command {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.command .el-input {
  width: 80%;
}
</style>
