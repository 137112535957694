<template>
  <div class="GlobalFormDemo">
    <GlobalForm :init-data="initData" :form-rules="formRules" :form-item-list="formItemList" :inline="true" round :needBtnLoading="true"
      @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label-width="80px" label="证书名称" slot="operationSelect" style="margin:0">
        <el-select v-model="webCertId" placeholder="请选择" clearable >
          <el-option v-for="item in RoleSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <template>
        <el-button icon="el-icon-download" size="small" type="success" :loading="exportLoading" @click="exportExcel">导出Excel</el-button>
      </template>
    </GlobalForm>
  </div>
</template>

<script>
import { MessageBox } from "element-ui";
import { formValidateMobile } from '@/utils/form-validate'
import _api from "@/utils/request";
export default {
  name: 'Form',
  data() {
    return {
      webCertId: "",
      SeachParams:{},
      RoleSelectList: [],
      initData: {
        state: "01",
      },
      formItemList: [
        { slotName: 'operationSelect' },
        {
          key: 'state',
          type: 'select',
          lableWidth: '80',
          labelName: '证书状态',
          option: [
            {
              value: '01',
              label: '生效中',
            }, {
              value: '02',
              label: '已过期',
            },
            {
              value: '03',
              label: '已停用',
            },
          ],
        },
        { slot: "AddSlot" }
      ],
      formRules: {
        mobile: [{
          validator: formValidateMobile,
          trigger: 'blur'
        }]
      },
      exportLoading: false
    }
  },
  created() {
    this.handleConfirm({state:"01"})
    _api.selectListWebcert().then(res => {
      if (res.code === 1) {
        this.RoleSelectList = res.data;
      }
    });
  },
  methods: {
    //查询
    handleConfirm(data,cd) {
      const SeachParams = {
        webCertId: this.webCertId,
        state: data.state,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      }
      this.SeachParams=SeachParams
      this.$store.commit('tagsView/SEACH_PARAMS', SeachParams)
    },
    //导出Excel
    exportExcel(e) {
      console.log(this.SeachParams);
      this.exportLoading = true
      _api
        .exportWebcertsexcel(this.SeachParams)
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "证书列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");
        }).finally(() => {
            this.exportLoading = false;
          });
    },
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {}
</style>
