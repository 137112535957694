<template>
  <div class="StaffManage-list">
    <GlobalInfoBar
        title="证书到期管理"
        content="管理平台使用的开发相关证书"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"
    >
      <div slot="filter">
        <StaffManageFrom />
      </div>
      <StaffManageTable />
    </GlobalChunk>
  </div>
</template>

<script>
import StaffManageFrom from './From'
import StaffManageTable from './Table'
export default {
  name: 'StaffManage-list',
  components: {StaffManageFrom,StaffManageTable},
  data(){
    return{
      userName:"",
      userPhone:"",
      roleId:"",
    }
  },
  methods:{
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-list{
}
</style>
